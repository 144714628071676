<template>
  <main class="myfestival_order_detail">
    <h1>결제내역/진행현황</h1>
    <div class="detail_container">
      <h3>내역조회</h3>
      <table>
        <tr>
          <th>결제일시</th>
          <td>{{ item.registerDate }}</td>
        </tr>
        <tr>
          <th>운영일수</th>
          <td v-if="item.product">{{ item.optionData.operatingDay }} 일</td>
        </tr>
        <tr>
          <th>운영개수</th>
          <td v-if="item.product">{{ item.optionData.operatingCount }} 개</td>
        </tr>
        <tr>
          <th>운영축제명</th>
          <td v-if="item.reference">{{ item.reference.festivalName }}</td>
          <td v-else>-</td>
        </tr>
        <tr>
          <th>운영기간</th>
          <td v-if="item.reference">{{ item.reference.festivalStartDate }}</td>
          <td v-else>-</td>
        </tr>
        <tr>
          <th>진행상태</th>
          <td>{{ item.status }}</td>
        </tr>
        <tr>
          <th>금액</th>
          <td>{{ item.total | comma }}</td>
        </tr>
        <tr>
          <th>결제방법</th>
          <td>{{ item.pgType }}</td>
        </tr>
        <tr v-if="item && item.refundDate != '0000-00-00 00:00:00'">
          <th>카드취소일자</th>
          <td></td>
        </tr>
        <tr v-if="item && item.refundDate != '0000-00-00 00:00:00'">
          <th>계좌환불일자</th>
          <td></td>
        </tr>
      </table>
    </div>
    <div class="btn_center">
      <base-button
        type="secondary"
        v-if="
          item.status == '주문(결제완료)' ||
          item.status == '검수 중' ||
          item.status == '보완요청'
        "
        @click="cancellation"
        >취소 요청</base-button
      >
      <base-button type="secondary" v-else @click="sendEmail"
        >1:1문의</base-button
      >
      <base-button type="primary" @click="$router.go(-1)">이전</base-button>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  name: "MypageFestivalOrderDetail",
  components: { BaseButton },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  data() {
    return {
      item: {},
      contentLoading: true,
      id: 0,
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async cancellation() {
      this.id = this.$route.params.id;
      let params = {
        id: this.id,
      };
      if (this.item.pgType == "카드") {
        await client.orderRefund(params, this.id).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "취소 요청이 정상적으로 처리되었습니다.",
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  closed: () => {
                    this.fetch();
                  },
                }
              );
            }
          },
          (error) => {
            if (error.response.status) {
              // let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "message",
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
            }
          }
        );
      } else {
        window.open("mailto:festival@nextinnovation.kr");
      }
    },
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
    async fetch() {
      this.id = this.$route.params.id;
      await client.orderFindOne(this.id).then(
        (response) => {
          const { data } = response;
          if (data.pgType == "card") {
            data.pgType = "카드";
          } else if (data.pgType == "transfer") {
            data.pgType = "무통장입금";
          } else {
            data.pgType = "기타";
          }
          if (data.status == 1) {
            data.status = "주문(결제완료)";
          } else if (data.status == 2) {
            data.status = "검수 중";
          } else if (data.status == 3) {
            data.status = "보완요청";
          } else if (data.status == 4) {
            data.status = "개발진행";
          } else if (data.status == 5) {
            data.status = "개발완료";
          } else if (data.status == 6) {
            data.status = "운영 중";
          } else if (data.status == 7) {
            data.status = "운영 종료";
          } else if (data.status == 8) {
            data.status = "취소(환불)";
          } else if (data.status == 9) {
            data.status = "환불";
          } else if (data.status == 10) {
            data.status = "주문(결제전)";
          }
          try {
            data.reference.festivalStartDate = `${this.$d(
              new Date(data.reference.festivalStartDate).getTime(),
              "short"
            )} ~ ${this.$d(
              new Date(data.reference.festivalEndDate).getTime(),
              "short"
            )}`;
          } catch (e) {
            console.log(e);
            data.festivalStartDate = "-";
          }
          this.item = data;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
